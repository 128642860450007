import Axios from 'axios';
import { mask } from 'vue-the-mask';
import moment from 'moment';

export default {
  directives: {mask},
  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      user: {
        name: '',
        profile: {
          country: {},
          state: {},
          city: {},
          nascimento: '',
        },
        network: {},
        financial: {},
        legal: {},
      },
      company: {
        community_flag: 0,
      },
      card: {
        number: "",
        due: "",
        holderName: "",
        vcc: "",
      },
      errors: {},
      syncing: false,
    }
  },

  mounted() {
    var _this = this;

    if (_this.$store.state.role == 'admin') _this.$router.push({ path: '/dashboard/admin' });
    if (_this.$store.state.role == 'professional') _this.$router.push({ path: '/dashboard/professional' });

    axios
    .get('/api/v1/users/' + this.me.id, {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.user = response.data.data;
        response.data.data.profile.nascimento = moment(this.user.profile.nascimento).format('YYYY-MM-DD');
        if (!this.user.profile.cpf || this.user.profile.cpf == "") {
          $('#collapse-conta').addClass('show');
          this.handleCollapse(5);
        }
        // console.log(this.user);
      }
    });

    axios
    .get('/api/v1/companies/' + this.me.company_id, {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.company = response.data.data;
      }
    });

  },

  methods: {
    formatDate(date) {
      return moment(date).format('MM/YYYY');
    },

    submit() {
      let _this = this;
      _this.syncing = true;

      EBANX.config.setMode('production'); // production/test
      EBANX.config.setPublishableKey('oKPf8oB0L4qOoNv3iDPY2uUwX5DVRvGStvYvG1jpQI0');
      EBANX.config.setCountry('br');

      var createTokenCallback = function(ebanxResponse) {
        if (ebanxResponse.data.hasOwnProperty('status')) {

          axios
          .put('/api/v1/companies/' + _this.me.company_id, {token: ebanxResponse.data.token}, {
            headers: {
              Authorization: 'Bearer ' + _this.TOKEN,
            }
          })
          .then(response => {
            if (!response.data.error) {
              alert("Cartão salvo com sucesso!");
              _this.$router.push({ path: '/dashboard/client/settings' });
            }
          });
        } else {
          var errorMessage = ebanxResponse.error.err.status_message || ebanxResponse.error.err.message;
          document.getElementById('status').textContent = 'Error ' + errorMessage;
        }
      }

      EBANX.card.createToken({
        card_number: this.card.number,
        card_name: this.card.holder,
        card_due_date: this.card.due,
        card_cvv: this.card.vcc
      }, createTokenCallback);

    },

    backToSettings() {
      return;
    }

  },

  computed: {

    owner() {
      return (this.user.id == this.company.users[0].id);
    }
  }

}
